import React from "react";
import PropTypes from 'prop-types';

import "@blueprintjs/core/lib/css/blueprint.css";

import { Slider, Intent } from "@blueprintjs/core";

Range.propTypes = {
    name: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

function Range ({name, min, max, step, value, onChange}) {
    let fn = (value) => {
        console.log(value);
        onChange(name, value + min);
    }
    return <div className={'control'}>
        <Slider name={name}
                onChange={fn}
                max={max - min}
                min={min - min}
                stepSize={step}
                value={value - min}
                labelRenderer={false}/>
    </div>
}

//<input type={'range'} name={name} min={min} max={max} step={step} value={value} onChange={fn}/>

export default Range;