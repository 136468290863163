import Rainbow from 'color-rainbow'

const COLOR_COUNT = 100;
export const COLOR_VAR_NAME = 'Color';

const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}).join('')

const utils = {
    generateColorArray: () => {
        return Rainbow.create(COLOR_COUNT).map(c => {
            let rgb = c.values.rgb;
            return rgbToHex(rgb[0], rgb[1], rgb[2])});
    },
    buildColorVariable: () => {
        return {name: COLOR_VAR_NAME, min: 0, max: COLOR_COUNT, step: 1, value: 33}
    }

}

export default utils;