import './App.css';
import React, {Component} from 'react';
import utils, {COLOR_VAR_NAME} from './util/utils';
import Range from "./component/range/Range";

import { FocusStyleManager } from "@blueprintjs/core";
FocusStyleManager.onlyShowFocusOnTabs();

class App extends Component{

    constructor(props) {
        super(props);
        this.state = {
            dodecahedron: null,
            colors: utils.generateColorArray(),
            sliders: {
                size: 0.1,
                scalar: 1.2,
            }
        }
    }

    componentDidMount() {
        this.loadWasm();
        window.addEventListener('resize', () => {

        })
    }

    setVariable = (name, value) => {
        let { variables, dodecahedron, colors } = this.state;
        variables.forEach(v => {if (v.name === name) v.value = value;});
        if (name === COLOR_VAR_NAME) {
            console.log(value, colors[value]);
            dodecahedron.set_color(colors[value]);
        } else {
            dodecahedron.set_var(name, value);
        }
        this.setState({variables});
    }

    loadWasm = async () => {
        try {
            const { DodecahedronRenderer } = await import('dodecahedron');
            let variables = DodecahedronRenderer.get_variables().all;
            let colorVariable = utils.buildColorVariable();
            let colors = utils.generateColorArray();
            let startColor = colors[colorVariable.value];
            variables.push(colorVariable);
            let dodecahedron = DodecahedronRenderer.new('canvas', startColor);
            this.setState({dodecahedron, variables, colors});
            this.draw();

        } catch(err) {
            console.error(`Unexpected error in loadWasm. [Message: ${err.message}]`);
        }
    };

    draw = () => {
        window.requestAnimationFrame(() => {
            this.state.dodecahedron.draw();
            this.draw();
        });
    }

    render() {
      let { variables } = this.state;
      return (
          <div className='app bp3-dark'>
              <div className='app-header'>
                  Dabell Tech.
              </div>
              <div className={'control-group'}>
                  {(variables || []).map(v => {
                      return <Range key={v.name} name={v.name} min={v.min} max={v.max} step={v.step} value={v.value} onChange={this.setVariable}/>
                  })}
              </div>
              <div className='app-footer'>
                  Dodecahedron: Rust, Web Assembly, Canvas.
              </div>
              <canvas id={'canvas'}></canvas>
          </div>
      );
    }
}

export default App;
